import {getFormProps, getInputProps, useForm} from '@conform-to/react'
import {getZodConstraint, parseWithZod} from '@conform-to/zod'
import * as E from '@react-email/components'
import {type ActionFunctionArgs, json, type MetaFunction, redirect,} from '@vercel/remix'
import {Form, useActionData, useSearchParams} from '@remix-run/react'
import {HoneypotInputs} from 'remix-utils/honeypot/react'
import {z} from 'zod'
import {errorJson} from "#app/common/reponses.ts";
import {GeneralErrorBoundary} from '#app/components/error-boundary.tsx'
import {ErrorList, Field} from '#app/components/forms.tsx'
import {StatusButton} from '#app/components/ui/status-button.tsx'
import {ProviderConnectionForm, providerNames,} from '#app/utils/connections.tsx'
import {prisma} from '#app/utils/db.server.ts'
import {sendEmail} from '#app/utils/email.server.ts'
import {checkHoneypot} from '#app/utils/honeypot.server.ts'
import {useIsPending} from '#app/utils/misc.tsx'
import {EmailSchema} from '#app/utils/user-validation.ts'
import {prepareVerification} from './verify.server.ts'
import type {LoaderFunctionArgs} from "@vercel/remix";
import { AnimatedTitle } from '#app/components/common/AnimatedTitle.js'
import React from "react";

export const SignupSchema = z.object({
  email: EmailSchema,
  intent: z.enum(['heroSignupForm', 'struggleSignupForm', 'readySignupForm', 'feelTheChangeSignupForm', 'signupForm']),
});

export async function loader({ request }: LoaderFunctionArgs) {
	return json({})
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const intent = formData.get('intent');

  if (typeof intent !== 'string' || !['heroSignupForm', 'struggleSignupForm', 'readySignupForm', 'feelTheChangeSignupForm', 'signupForm'].includes(intent)) {
    return json({ error: 'Invalid form submission' }, { status: 400 });
  }

  checkHoneypot(formData);

  const submission = await parseWithZod(formData, {
		schema: SignupSchema.superRefine(async (data, ctx) => {
			const existingUser = await prisma.user.findUnique({
				where: { email: data.email },
				select: { id: true },
			})
			if (existingUser) {
				ctx.addIssue({
					path: ['email'],
					code: z.ZodIssueCode.custom,
					message: 'A user already exists with this email',
				})
				return
			}
		}),
    async: true,
  });

  if (submission.status !== 'success') {
    return json({ intent, result: submission.reply() }, { status: 400 });
  }
  const { email } = submission.value;
  const { verifyUrl, redirectTo, otp } = await prepareVerification({
    period: 10 * 60,
    request,
    type: 'onboarding',
    target: email,
  });

  const response = await sendEmail({
    to: email,
    subject: `Welcome to best of me!`,
    react: <SignupEmail onboardingUrl={verifyUrl.toString()} otp={otp} />,
  });

  if (response.status === 'success') {
    return redirect(redirectTo.toString());
  } else {
    return json(
      {
        intent,
        result: submission.reply({ formErrors: [response.error.message] }),
      },
      {
        status: 500,
      },
    );
  }
}


export function SignupEmail({
	onboardingUrl,
	otp,
}: {
	onboardingUrl: string
	otp: string
}) {
	return (
		<E.Html lang="en" dir="ltr">
			<E.Container>
				<h1>
					<E.Text>Welcome to best of me!</E.Text>
				</h1>
				<p>
					<E.Text>
						Here's your verification code: <strong>{otp}</strong>
					</E.Text>
				</p>
				<p>
					<E.Text>Or click the link to get started:</E.Text>
				</p>
				<E.Link href={onboardingUrl}>{onboardingUrl}</E.Link>
			</E.Container>
		</E.Html>
	)
}

export const meta: MetaFunction = () => {
	return [{ title: 'Sign Up | best of me' }]
}

export default function SignupRoute() {
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	const [form, fields] = useForm({
		id: 'signup-form',
		constraint: getZodConstraint(SignupSchema),
		lastResult: actionData?.result,
		onValidate({ formData }) {
			const result = parseWithZod(formData, { schema: SignupSchema })
			return result
		},
		shouldRevalidate: 'onBlur',
	})

  // bg-gradient-to-b from-yellow-50 to-white
	return (
		<div className="container flex flex-col justify-center pb-32 pt-20 text-gray-800 ">
			<div className="text-center">
				<AnimatedTitle>Let's start your journey!</AnimatedTitle>
				<p className="mt-3 text-body-md text-gray-600 font-quicksand">
					Please enter your email.
				</p>
			</div>
			<div className="mx-auto mt-16 w-full max-w-md px-8">
				<Form method="POST" {...getFormProps(form)}>
					<HoneypotInputs/>
					<Field
						labelProps={{
							htmlFor: fields.email.id,
							children: 'Email',
							className: 'text-gray-700',
						}}
						inputProps={{
							...getInputProps(fields.email, {type: 'email'}),
							autoFocus: true,
							autoComplete: 'email',
							className: `border-gray-300 text-gray-800 bg-white focus:border-blue-500 focus:ring-blue-500 ${
								fields.email.errors ? 'border-red-500' : ''
							}`,
						}}
						errors={fields.email.errors}
					/>
					<input type="hidden" name="intent" value="signupForm" />
					<ErrorList errors={form.errors} id={form.errorId}/>
					<div className="flex items-center justify-between gap-6 pt-3">
						<StatusButton
							className="w-full bg-indigo-900 hover:bg-indigo-800 text-white font-bold text-base py-2 px-4 rounded transition duration-300 ease-in-out"
							status={isPending ? 'pending' : form.status ?? 'idle'}
							type="submit"
							disabled={isPending}
						>
							Submit
						</StatusButton>
					</div>
				</Form>
				{/* <ul className="mt-5 flex flex-col gap-5 border-b-2 border-t-2 border-border py-3">
					{[].map(providerName => (
						<li key={providerName}>
							<ProviderConnectionForm
								type="Signup"
								providerName={providerName}
								redirectTo={redirectTo}
							/>
						</li>
					))}
				</ul> */}
			</div>
		</div>
	)
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}